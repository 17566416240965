<template>
  <div>
    <div class="login_box_message">
      Please indicate your username and new password to reset your password.
    </div>
    <div class="login_form_wrap">
      <b-form-group label="Username">
        <b-form-input
          v-model="username"
          name="username"
          types="text"
          value=""
          autofocus
        />
      </b-form-group>

      <b-form-group label="Password">
        <password-input
          v-model="newPassword"
          name="password"
        />
      </b-form-group>

      <b-form-group label="Confirm Password">
        <password-input
          v-model="newPasswordConfirmation"
          name="password_confirmation"
        />
      </b-form-group>

      <button
        class="login_input_submit"
        @click="submitForm"
      >
        Reset Password
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useBvModal } from '@/composables';
import { useRoute } from 'vue-router/composables';
import PasswordInput from './PasswordInput.vue';

const { bvModal } = useBvModal();
const username = ref(null);
const newPassword = ref(null);
const newPasswordConfirmation = ref(null);
const route = useRoute();

function submitForm() {
  axios.post('/api/password/reset', {
    username: username.value,
    token: route.params.token,
    password: newPassword.value,
    password_confirmation: newPasswordConfirmation.value,
  }).then(() => {
    bvModal.msgBoxOk(
      'Password changed successfully',
      {
        title: 'Password Changed',
        headerBgVariant: 'success',
        headerTextVariant: 'light',
        okVariant: 'success',
      },
    ).then(() => {
      window.location.href = '/';
    });
  }).catch((error) => {
    bvModal.msgBoxOk(
      `Failed to change password, ${error.response.data.message}`,
      {
        title: 'Error',
        headerBgVariant: 'dark',
        headerTextVariant: 'light',
        okVariant: 'dark',
      },
    );
  });
}
</script>
