<template>
  <div>
    <div class="login_box_message">
      To reset your password, please submit the username used with your account. You will receive an e-mail with a link to reset your password.
    </div>
    <div class="login_form_wrap">
      <b-form-group label="Username">
        <b-form-input
          v-model="username"
          name="username"
          types="text"
          autofocus
        />
      </b-form-group>
      <b-btn @click="submitForm">
        Send Password Reset Link
      </b-btn>
    </div>

    <div class="login_box_message">
      <CookieConsent />
    </div>
    <div class="login_box_message">
      <b-btn href="/">
        Back
      </b-btn>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useBvModal } from '@/composables';
import CookieConsent from './CookieConsent.vue';

const { bvModal } = useBvModal();
const username = ref(null);

function submitForm() {
  axios.post('/api/password/email', {
    username: username.value,
  }).then((response) => {
    bvModal.msgBoxOk(
      response.data.message,
      {
        title: 'Success',
        headerBgVariant: 'success',
        headerTextVariant: 'light',
        okVariant: 'success',
      },
    ).then(() => {
      window.location.href = '/';
    });
  }).catch((error) => {
    bvModal.msgBoxOk(
      error.response.data.message,
      {
        title: 'Error',
        headerBgVariant: 'dark',
        headerTextVariant: 'light',
        okVariant: 'dark',
      },
    );
  });
}
</script>

<style lang="scss" scoped>

</style>
