<template>
  <div>
    <div class="login_box_message">
      To request your username, please submit the email used with your account. You will then receive an e-mail with the username information.
    </div>
    <div class="login_form_wrap">
      <b-form-group label="Email">
        <b-form-input
          v-model="email"
          name="email"
          types="text"
          autofocus
        />
      </b-form-group>
      <b-btn @click="submitForm">
        Send Username Information
      </b-btn>
    </div>

    <div class="login_box_message">
      <CookieConsent />
    </div>
    <div class="login_box_message">
      <b-btn href="/">
        Back
      </b-btn>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useBvModal } from '@/composables';
import CookieConsent from './CookieConsent.vue';

const { bvModal } = useBvModal();
const email = ref(null);

function submitForm() {
  axios.post('/api/username/recovery', {
    email: email.value,
  }).then((response) => {
    bvModal.msgBoxOk(
      response.data.message,
      {
        title: 'Success',
        headerBgVariant: 'success',
        headerTextVariant: 'light',
        okVariant: 'success',
      },
    ).then(() => {
      window.location.href = '/';
    });
  }).catch(() => {
    bvModal.msgBoxOk(
      'Failed to submit request for recovery',
      {
        title: 'Error',
        headerBgVariant: 'dark',
        headerTextVariant: 'light',
        okVariant: 'dark',
      },
    );
  });
}
</script>

<style lang="scss" scoped>

</style>
