import CookiePolicy from './components/CookiePolicy.vue';
import UsernameRecovery from './components/UsernameRecovery.vue';
import ForgottenPassword from './components/ForgottenPassword.vue';
import LoginPage from './components/LoginPage.vue';
import ActivateUser from './components/ActivateUser.vue';
import ResetPassword from './components/ResetPassword.vue';

const unauthenticatedRoutes = [
  { path: '/login', component: LoginPage },
  { path: '/policies/cookies', component: CookiePolicy },
  { path: '/username/recovery', component: UsernameRecovery },
  { path: '/password/request', component: ForgottenPassword },
  { path: '/activateuser/:id', component: ActivateUser },
  { path: '/password/reset/:token', component: ResetPassword },

];

export default unauthenticatedRoutes;
