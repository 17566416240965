import './bootstrap';
import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import VueRouter from 'vue-router';

import PasswordInput from './components/PasswordInput.vue';
import CookieConsent from './components/CookieConsent.vue';
import CookiePolicy from './components/CookiePolicy.vue';

import unauthenticatedRoutes from './unauthenticatedRoutes';

Vue.use(BootstrapVue);

Vue.component('PasswordInput', PasswordInput);
Vue.component('CookieConsent', CookieConsent);
Vue.component('CookiePolicy', CookiePolicy);

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: unauthenticatedRoutes,
});

window.VueApp = new Vue({
  router,
  el: '#app',
});
